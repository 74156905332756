<script>
import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";
import commonApi from "../apiModel/apiModel";

import { FormGroupPlugin } from 'bootstrap-vue'
Vue.use(FormGroupPlugin)

import { FormCheckboxPlugin } from 'bootstrap-vue'
Vue.use(FormCheckboxPlugin)

import { TooltipPlugin } from 'bootstrap-vue'
Vue.use(TooltipPlugin)

import { DropdownPlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)

import 'bootstrap-vue/dist/bootstrap-vue.css'

// Add class when scrolled
import ClassOnScroll from 'vue-class-on-scroll'
import searchBoxPopup from "../../pages/popUpModals/searchBoxPopup";

// carousel
	import carousel from 'vue-owl-carousel'

	// Expandable
	import {
	VsaList,
	VsaItem,
	VsaHeading,
	VsaContent,
	VsaIcon
} from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';

import Vue from 'vue'

// make any element sticky
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'


// input cart - 1 + 
	import { IntegerPlusminus } from 'vue-integer-plusminus'
	
// For Scroll Spy (lunch,Startes)
	import VueScrollactive from 'vue-scrollactive';
	
Vue.use(VueScrollactive);

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
Vue.use(VueCustomTooltip)

// import VueShimmer from '@gomarky/vue-shimmer' // shimmer div
// Vue.use(VueShimmer)

// Vue.use(VueCustomTooltip, {
//   name: 'VueCustomTooltip',
//   color: '#fff',
//   background: '#000',
//   borderRadius: 12,
//   fontWeight: 400,
// })

import { VuePicker, VuePickerOption } from '@invisiburu/vue-picker'

import '@invisiburu/vue-picker/dist/vue-picker.min.css'


Vue.component('VuePicker', VuePicker)
Vue.component('VuePickerOption', VuePickerOption)

// import VueHorizontalList from 'vue-horizontal-list';
	// import VueDropdown from 'vue-dynamic-dropdown'

// 	  can register the component globally By adding this in main.js

// Vue.component('vue-dropdown', VueDropdown);

export default {
	data() {
		return {
			cusineAddonModalShow:true,
			selectedAddonCartItems:[],
			itemTotalCartModal:'',
			cusinieAddonsDetails: "",
			isPickupDisabled:0,
			averageRating:"",

			list:undefined, //cafeteria menu list
			restDetails:undefined,
			cusineDetailsDataArr: [], //single cusine data,
			cusineTypeIndex: undefined, // index of cusine Type data,
			cusineItemIndex: undefined, // index of cusine Item,
			cusineModalShow: true, // cusine modal hide show
			itemInCartArr: [], // item that are added in cart 
			cartDetails: [], // cart count total price etc.
			cartItemsDetails: [], // all the dishes in cart
			cuisineItemDetails: undefined,
			selectedAddOnItems:[],
			licenseNO:'',
			allFoodTypes:[],
			itemFromModal: 1,
			itemFromCard: 1,
			cartQuantity: 1,
			cartStatus: undefined, // get cart status with api
			selectFoodType: '',
			addOnsData: undefined,
			getAllMealTypesTabs:[],
			allReviewsData:[],
			mealType:'',
			mealTypeID:-1,
			mealTypeDisplayName:'',
			restaurantIdentifier:'',
			Shimmer: 0,
			userID: '',
			flushedcart:0,
			corporateBranchID: '',
			itemTotalPopup:'',
			navIcons :['<i class="bx bx-chevron-left"></i>', '<i class="bx bx-chevron-right"></i>'],
		show: false,
		variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark', 'white'],
		// headerBgVariant: 'dark',
		// headerTextVariant: 'light',
		// bodyBgVariant: 'light',
		// bodyTextVariant: 'dark',
		footerBgVariant: 'white',
		footerTextVariant: 'dark',
			options: {
			topSpacing: 95,
			resizeSensor: true,
			stickyClass: "is-affixed",
		//   bottom: 0,
		},
			preBuyVouchersObj:'',
			addOnError:'',
		}
	},
	
		mounted()
	{
		// console.log(this.$userData.userID);
	// axios.post('api/api1/cafeteriamenu', formData,{
	//             headers: {
	//             'Access-Control-Allow-Origin': '*',
	// 			"Content-Type": "text/html",
	// 			"Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept"
	//             }
	//             })	
	if(this.$route.params.mealType)
	{
		var mealTypeLcl = this.$route.params.mealType;
		this.mealType = mealTypeLcl;
	}
	// sessionStorage.setItem('mealType',this.mealType);
	// console.log(sessionStorage.getItem('mealType'));

	if (localStorage.getItem('mealTypeTabsData') !== null) {
			this.getAllMealTypesTabs = JSON.parse(localStorage.getItem('mealTypeTabsData')).mealTypeTabs;

			var valObj = this.getAllMealTypesTabs.filter(function(elem){
				// console.log(elem.menuTabType+","+mealTypeLcl);
				if(elem.menuTabType == mealTypeLcl) return elem.mealTypeID;
			});
			if(valObj.length > 0)
			{
			// console.log(valObj);
				this.mealTypeID = valObj[0].mealTypeID;
				this.mealTypeDisplayName = valObj[0].mealTypeDisplayName;
			}
		}
		// console.log(this.mealTypeID);

	if (localStorage.getItem('mealTypeTabsData') !== null) {
			this.getAllMealTypesTabs = JSON.parse(localStorage.getItem('mealTypeTabsData')).mealTypeTabs;
		}

		if (this.$userData !== null) {
			this.userID = this.$userData.userID;
			this.corporateBranchID = this.$userData.corporateBranchID;
			this.getCartDetails();
		}
			if(this.$route.params.id){
				var restaurantIdentifier = this.$route.params.id;
				this.restaurantIdentifier = this.$route.params.id;
			}

			this.getRestaurantDetails(restaurantIdentifier);
			// this.getCafeMenuByID();

		// this.getCartStatus();
		this.getUserProfile();
	},

	// computed: {
	//     isInArray: function() {
	//         return this.itemInCartArr.includes(value);
	//     }
	// },

	methods: {
		checkAddonReq(addonsArr,cusineDataArr, typeIndex, cusineIndex, isItemFromModal, isfromCart) //isfromCart =>1 from cart popup ,2=>cisuine menu popup
		{
			var selectedAddons = [];
			if (isfromCart == 1)
			{
				selectedAddons = this.selectedAddonCartItems;
			} else {
				selectedAddons = this.selectedAddOnItems;
			}

			this.addOnError = '';
			if (addonsArr.length > 0)
			{
				for (var i = 0; i < addonsArr.length; i++)
				{	
					// (addonsArr[i].required == 0 || addonsArr[i].required == 1) && 
					if ((addonsArr[i].selectionType == 1 || addonsArr[i].selectionType == 2))
					{
						var valObj = selectedAddons.filter(function(elem) {
							if (elem.addonGroupID == addonsArr[i].addonGroupID) return elem;
						});

						if (addonsArr[i].required == 1 && valObj.length >= 0) {
							if (valObj.length != addonsArr[i].selectionType)
							{
								this.addOnError = "Select maximum of "+addonsArr[i].selectionType+" "+addonsArr[i].groupName;
								return;
								// this.addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal);
							}
						}

						if (addonsArr[i].required == 0 && valObj.length > 0) {
							if (valObj.length > addonsArr[i].selectionType)
							{
								this.addOnError = "Select maximum of "+addonsArr[i].selectionType+" "+addonsArr[i].groupName;
								return;
								// this.addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal);
							}
						}

						// else
						// {	
						// 	this.addOnError = "You can select maximum of "+addonsArr[i].selectionType+" "+addonsArr[i].groupName;
						// 	break;
						// }
					}

					if (addonsArr[i].required == 1 && addonsArr[i].selectionType == 3)
					{
						var valObj = selectedAddons.filter(function(elem) {
							if (elem.addonGroupID == addonsArr[i].addonGroupID) return elem;
						});

						if (valObj.length == 0)
						{
							this.addOnError = "Select minimum of 1 "+addonsArr[i].groupName;
							return;
							// this.addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal);
						}
						// else
						// {	
						// 	this.addOnError = "You can select maximum of "+addonsArr[i].selectionType+" "+addonsArr[i].groupName;
						// 	break;
						// }
					}
				}

				if (isfromCart == 1)
				{
					this.updateItemAddonModal(cusineDataArr);
				} else {
					this.addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal);
				}
			}
		},

		oneItemFreeAlert()
		{
			this.cusineModalShow = false;
			this.$swal('You have already consumed free meal for today');
		},

		getRatingsReview(restBranchID){
			
			this.axios.post(
			"getFdbackReviewRestaurants", {
			'restBranchId':restBranchID,           
			}
		)
		.then((response) => {
			//this.tableData = response.data.data;
			this.averageRating = response.data.Avg;
			this.allReviewsData = response.data.data;
			//alert(this.tableData);
		//    alert(this.averageRating);
			
		});
		},
		getAllTheReviews(){		
				this.$bvModal.show("modal-allReviewsmodal");
		},

		addOnsModal(cartID, cuisineID, restBranchID)
		{
			this.addOnError = '';
			let getCuisineDetails = JSON.stringify({ 
				cartID: cartID,
				cuisineID: cuisineID,
				restBranchID: restBranchID
				});
			this.axios.post('/getCuisineDetailsForCart', getCuisineDetails)
			.then((response)=>{
			// console.log(response.data.cuisineDetails);
			let status = response.status;
			if(status == 200 && response.data.status == 200)
			{
				this.cusinieAddonsDetails = response.data;

				/////////// to get total sum of item from cart ///////////////
				this.itemFromCartModal = this.cusinieAddonsDetails.cuisineDetail.quantity;
				this.selectedAddonCartItems = response.data.cartSelectedAddonsArr;
				var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
				this.itemTotalCartModal = (parseInt(this.cusinieAddonsDetails.cuisineDetail.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
				/////////// to get total sum of item from cart ///////////////
				this.$bvModal.show("modal-cuisinePopup");
			}
			}).catch((error) => {
				console.log(error.response.data.message);
			});
		},

		selectCartModalAddons(itemPrice)
		{
			// console.log(this.selectedAddonCartItems);
			// console.log(itemPrice);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.itemFromCartModal);
		},

		updateCartDataFromModal(cusineDetailsDataArr)
		{
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
		},

		updateItemAddonModal(cusineDetailsDataArr)
		{
			this.cusineAddonModalShow = false;
			// console.log(cusineDetailsDataArr);
			var sum = this.selectedAddonCartItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalCartModal = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromCartModal);
			this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			this.arrayColumn = commonApi.arrayColumn;
			var selectedAddonCartItemsIDArr = this.arrayColumn(this.selectedAddonCartItems, 'addOnItemID');

			var newQuantity = this.itemFromCartModal;
			var itemDishID = cusineDetailsDataArr.id;
			var restBranchID = cusineDetailsDataArr.restaurantid;

			if(this.itemFromCartModal < cusineDetailsDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDetailsDataArr.minorderquantity+ "","Remove Item?","")
				.then(() => {
								var newQuantity = 0;
								let getCartDetailsData = JSON.stringify({ 
																		userID: this.userID,
																		dishID: itemDishID,
																		quantity: newQuantity,
																		restBID: restBranchID,
																		addOnItems:selectedAddonCartItemsIDArr,
																		});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
				})
				.catch(error => {
						cusineDetailsDataArr.quantity = cusineDetailsDataArr.minorderquantity;
						});
			}
			else
			{
				let getCartDetailsData = JSON.stringify({ userID: this.userID,
														dishID: itemDishID,
														quantity: newQuantity,
														restBID: restBranchID,
														addOnItems:selectedAddonCartItemsIDArr,
															});
								this.selectedAddOnItems = []; // null value of previously selected addons
								Vue.axios.post('/updateCart', getCartDetailsData)
									.then((response)=>{
										// console.log(response);
										let status = response.status;
										if(status == 200 && response.data.status == 200)
										{
												// this.getCheckoutDetails(this.coupon, 0);
												// this.getCheckoutDetails();
												// this.getCafeMenuByID();
												location.reload();
										}
										// this.cartStatus = response.data.cartdetails;
									}).catch((error) => {
											console.log(error.response.data.message);
									});
			}
		},

		getCheckOutAccess()
		{
			if(this.$isUserLoggedIn != null && this.$isUserLoggedIn != '' && this.$isUserLoggedIn == 1)
			{
				this.$router.push('/checkOut');
			}
			else
			{
				this.$bvModal.show("modal-visitor");
			}

			// this.$analytics.logEvent('checkout_click', { 
			//     userID: localStorage.getItem('userID'), 
			//     userName: localStorage.getItem('userName'), 
			// }, { 'debug_mode':true })
		},

		getPreBuyVoucherDetails(preBuyVouchers)
		{
			if(preBuyVouchers != null || preBuyVouchers != '')
			{
				this.preBuyVouchersObj = preBuyVouchers;
				this.$bvModal.show("modal-couponDetails");
			}
		},

		getUserProfile()
		{
			this.axios.get('/getUserProfile')
			.then((res)=>{
				// console.log(res);
				let status = res.status;
				if(status == 200 && res.data.status==200)
				{
					this.userID = res.data.userData.userID;
					this.userMobileNo = res.data.userData.mobileNo;
				}else{
					console.log(res.data.message);
				}
			});
		},

		refreshToken()
		{
			var userID=this.userID === undefined ?0:this.userID;
			this.axios.post('/generateNewToken',{"userID":userID})
			.then((res)=>{
				// console.log(res);
				let status = res.status;
				if (status == 200 && res.data.status == 200) {
					if (res.data.refreshedToken && res.data.refreshedToken != "") {
						var userData = {
							utid: res.data.refreshedToken,
						};
						localStorage.setItem("uTid", JSON.stringify(userData));
						location.reload();
						// this.getRestaurantDetails(restaurantIdentifier);
					}
				} else {
					console.log(res.data.message);
				}
			}).catch((error) => {
				if (401 === error.res.status) {
					this.Shimmer = 1;
					this.list = [];
					this.refreshToken();
				}
			});
		},

		
		getRestaurantDetails(restaurantIdentifier)
		{
			// console.log(restaurantIdentifier);
			if(restaurantIdentifier === undefined)
			{
				if(this.$route.params.id){
					var restaurantIdentifier = this.$route.params.id;
				}
			}
			else
			{
				var restaurantIdentifier = restaurantIdentifier;
			}
			let restMenuFormData = JSON.stringify({ userID: this.userID,
											restaurantIdentifier: restaurantIdentifier,
											foodTypeID:this.selectFoodType,
											mealType: this.mealTypeID
												});

			Vue.axios.post('/getRestaurantDetialsByID', restMenuFormData)
			.then((res)=>{
				let status = res.status;
				if(status == 200 && res.data.status==200)
				{
					if(res.data.token && res.data.token != '')
					{
						var userData = {
									"utid":res.data.token,
								}
						localStorage.setItem('uTid',JSON.stringify(userData));
						location.reload();
					}
				this.Shimmer = 1;
				this.list = res.data.data;
				this.restDetails = res.data.restaurantDetails;
				this.licenseNO = res.data.licenseNO;
				this.allFoodTypes = res.data.allFoodTypes;
				// this.allFoodTypes = res.data.allFoodTypes;

				/////////////////// replace Meta values Start//////////////////////
				if(res.data.restaurantDetails)
				{
					///////////// set Rest Description //////////////////////////
					if(res.data.restaurantDetails.metaDescription != '' && res.data.restaurantDetails.metaDescription != null && res.data.restaurantDetails.metaDescription != undefined)
					{
						var ogDesc = document.querySelector('meta[property="og:description"]').setAttribute('content',res.data.restaurantDetails.metaDescription);
						// ogDesc.setAttribute('content',res.data.restaurantDetails);
					}
					///////////// set Rest Description //////////////////////////

					///////////// set Rest Image  //////////////////////////
					if(res.data.restaurantDetails.image != '' && res.data.restaurantDetails.image != null && res.data.restaurantDetails.image != undefined)
					{
						var ogImage = document.querySelector('meta[property="og:image"]').setAttribute('content',res.data.restaurantDetails.image);
					}
					///////////// set Rest Image  //////////////////////////

					///////////// set Rest title  //////////////////////////
					if(res.data.restaurantDetails.metaTitle != '' && res.data.restaurantDetails.metaTitle != null && res.data.restaurantDetails.metaTitle != undefined)
					{
						var ogTitle = document.querySelector('meta[property="og:title"]').setAttribute('content',res.data.restaurantDetails.metaTitle);

					}
					///////////// set Rest title  //////////////////////////

					this.getRatingsReview(this.restDetails.restaurantID);
				}

				}else{
					// console.log(res.data.message);
					this.Shimmer = 1;
					this.list = [];
					this.refreshToken();
					// alert(res.data.message);
					// this.$router.push('/');
				}
			}).catch((error) => {
				if (401 === error.response.status) {
					this.Shimmer = 1;
					this.list = [];
					this.refreshToken();
				}
			});	
		},
		changeFoodType()
		{
			this.getRestaurantDetails();
		},

		makeToast()
		{
			this.$bvToast.toast('Toast body content', {
			title: `Variant `,
			variant: danger,
			solid: true
		})
		},
		
		// getCafeMenuByID()
		// {
		// 	this.Shimmer = 0;
		// 		let cafeteriaMenuFormData = JSON.stringify({ userID: this.userID,
		// 									corporateBranchID: this.corporateBranchID,
		// 									foodTypeID:this.selectFoodType
		// 										});

		// 	Vue.axios.post('/getCafeMenuByCorporateID', cafeteriaMenuFormData)
		// 	.then((res)=>{
		// 		let status = res.status;
		// 		if(status == 200 && res.data.status==200)
		// 		{
		// 		this.Shimmer = 1;
		// 		// this.list = res.data.data;
		// 		this.licenseNO = res.data.licenseNO;
		// 		this.allFoodTypes = res.data.allFoodTypes;
		// 		}else{
		// 			// console.log(res.data.message);
		// 			alert(res.data.message);
		// 			this.$router.push('/');
		// 		}
		// 	});
		// },

		getCusineDetails(cusineDataArr, typeIndex, cusineIndex)
		{
				this.getCusinieDetailsCommonApi = commonApi.getCusineDetailsApi;
				this.getCusinieDetailsCommonApi(cusineDataArr, typeIndex, cusineIndex);
				if(cusineDataArr.mealType == 2 || cusineDataArr.mealType == 3)
				{
					this.itemFromModal = cusineDataArr.minorderquantity;
				}
		},

		selectAddons(itemPrice)
		{
			// console.log(this.selectedAddOnItems);
			var sum = this.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
			this.itemTotalPopup = (parseInt(itemPrice) + parseInt(sum)) * parseInt(this.itemFromModal);
		},


		freeMealCheckOut(cusineDataArr, typeIndex, cusineIndex, isItemFromModal)
		{
			// console.log(cusineDataArr);
			// return;
			this.isPickupDisabled = 1;
			this.cusineModalShow = false;
			if(this.userMobileNo == null || this.userMobileNo == '')
			{
				this.$bvModal.show("modal-mobile");
			}
			else
			{
				this.$confirm("Do you want to pickup "+cusineDataArr.name+"","Are you sure?","")
					.then(() => {
						var corpMealID = 0;
						var corpMealPlanID = 0
						if(this.mealTypeID == -2)
						{
							var corpMealID = cusineDataArr.corpMealID;
							var corpMealPlanID = cusineDataArr.corpMealPlanID;
						}
						// this.selectedAddOnItems = []; // null value of previously selected addons
						// Add to cart API
						let addItemToCartData = JSON.stringify({ 
																// userID: this.userID,
																cuisineID: cusineDataArr.id,
																quantity: 1,
																// addOnItems:selectedAddOnItems,
																mealTypeID: this.mealTypeID, //-2 =>corporatemeals,
																corpMealPlanID: corpMealPlanID,
																corpMealID: corpMealID,
																});
						this.axios.post('/freeMealCheckOut', addItemToCartData)
							.then((response)=>{
								let status = response.status;
							if(status == 200 && response.data.status == 200 && response.data.urlToRedirect != "")
							{
								this.$router.push(response.data.urlToRedirect);
								this.isPickupDisabled = 0;
							}
							else
							{
								this.isPickupDisabled = 0;
								// alert(response.data.message);
								this.error = response.data.message;
								return error;
							}
								// this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
							}).catch((error) => {
									this.isPickupDisabled = 0;
									// console.log(error.response.data.message);
									this.error = "Something Went Wrong";
									return error;
							});
					});
			}
		},

		addItemToCart(cusineDataArr, typeIndex, cusineIndex, isItemFromModal)
		{
			this.cusineModalShow = false;
			this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			this.flushCartDataAndAddItemToCartCommonApi = commonApi.flushCartDataAndAddItemToCart;
			
			this.arrayColumn = commonApi.arrayColumn;
			var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');
			// console.log(this.cartDetails[0].restBranchID);

			// if(this.cartDetails.length != 0 && ((this.cartDetails[0].isMeal != this.mealTypeID) || cusineDataArr.restaurantid != this.cartDetails[0].restBranchID))
			if(this.cartDetails.length != 0 && (cusineDataArr.restaurantid != this.cartDetails[0].restBranchID))
			{
			// 	// for confirm modal syntax is 1.message,2.title,3.type
				this.$confirm("Your cart contains items from Other restaurant. Would you like to reset your cart before adding this item?","Items already in cart","")
				.then(() => {
					// console.log(this.flushedcart);
			// 		// userID and restBranchID to flush previous cartData and cusineData Arr for add items to cart
					// this.flushCartDataAndAddItemToCartCommonApi(this.cartDetails[0].restBranchID, cusineDataArr.restaurantid);
					// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, this.selectedAddOnItems);
					this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, selectedAddOnItemsIDArr, this.mealTypeID);
				});
			}
			else
			{
				// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, this.selectedAddOnItems);
					this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, selectedAddOnItemsIDArr, this.mealTypeID);
					// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, selectedAddOnItemsIDArr);
			}

			// this.addItemToCartCommonApi = commonApi.addItemTocartApi;
			// this.addItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, isItemFromModal, this.itemFromModal, this.selectedAddOnItems);
			// this.cusineModalShow = false;

		},

		// addItemToCartEvent(cusineDataArr)
		// {
		// 	this.$analytics.logEvent('add_cart', { 
		// 		userID: localStorage.getItem('userID'), 
		// 		userName: localStorage.getItem('userName'),
		// 		itemID: JSON.stringify(cusineDataArr.id),
		// 		itemName: JSON.stringify(cusineDataArr.name),
		// 		itemMealType: JSON.stringify(cusineDataArr.meal_type),
		// 		itemRestID: JSON.stringify(cusineDataArr.restaurantid),
		// 		itemRestName: JSON.stringify(cusineDataArr.restaurantName),
		// 	}, { 'debug_mode':true })
		// },

		// getCartStatus()
		// {
		// 	let cartStatusFormData = new FormData();  
		// 			cartStatusFormData.append('userid', 3576);
		// 			cartStatusFormData.append('token', '');
		// 	Vue.axios.post('/_cartStatus', cartStatusFormData)
		// 	.then((response)=>{
		// 		let status = response.status;
		// 		if(status == 200)
		// 		{
		// 			this.cartStatus = response.data.cartdetails;
		// 			// console.log(this.cartStatus);
		// 		}
				
		// 	});
		// },

		// getCusineById(cusineId)
		// {
		// 	let getCuisineDetails = JSON.stringify({ 
		// 		// userID: this.userID,
		// 											cuisineID: cusineId,
		// 											});
		// 	Vue.axios.post('/getCuisineDetailsByID', getCuisineDetails)
		// 		.then((response)=>{
		// 			// console.log(response.data.cuisineDetails);
		// 			let status = response.status;
		// 			if(status == 200 && response.data.status == 200)
		// 			{
		// 				this.cuisineItemDetails = response.data.cuisineDetails;
		// 			}
		// 			// this.cartStatus = response.data.cartdetails;
		// 		}).catch((error) => {
		// 				console.log(error.response.data.message);
		// 		});
		// 	// let cusineFormData = new FormData();  
		// 	// 		cusineFormData.append('userid', 3576);
		// 	// 		cusineFormData.append('cuisineid', cusineId); // 2052, 2146
		// 	// Vue.axios.post('/_getcuisinesbyid', cusineFormData)
		// 	// .then((response)=>{
		// 	// 	// console.log(response.data.sections);
		// 	// 	let status = response.status;
		// 	// 	if(status == true)
		// 	// 	{
		// 	// 		this.addOnsData = response.data.sections;
		// 	// 		// console.log(this.response);
		// 	// 	}
		// 	// });
		// },
		getCusineById(cusineId,cusineDataArr)
		{
			this.getCusinieByIDCommonApi = commonApi.getCusinieByIDApi;
			this.getCusinieByIDCommonApi(cusineId, this.userID, cusineDataArr);
		},

		getCartDetails()
		{
			// let getCartDetailsData = JSON.stringify({ userID: this.userID,
			// 										});
			// Vue.axios.post('/getCartDetails', getCartDetailsData)
			Vue.axios.get('/getCartDetails')
				.then((response)=>{
					// console.log(response);
					let status = response.status;
					if(status == 200 && response.data.status == 200)
					{
						if(response.data.cartDetails !== '' && response.data.cartItems !== '')
						{
							this.loadCartDetails(response.data.cartDetails, response.data.cartItems);
						}
						else
						{
							this.cartDetails = [];
							this.cartItemsDetails = [];
						}
					}
					// this.cartStatus = response.data.cartdetails;
				}).catch((error) => {
						console.log(error.response.data.message);
				});
		},

		loadCartDetails(cartDetailsArr, cartItemsArr)
		{
							// console.log('in loadCartDetails');
							// console.log(cartDetailsArr);
							// console.log(cartItemsArr);
			this.cartDetails = cartDetailsArr;
			this.cartItemsDetails = cartItemsArr;
		},
		updateItemToCartModal(cusineDetailsDataArr,itemData, typeIndex, cusineIndex, itemFrom)
				{
					var sum = this.selectedAddOnItems.reduce((acc, item) => acc + item.price, 0);
					this.itemTotalPopup = (parseInt(cusineDetailsDataArr.price) + parseInt(sum) )* parseInt(this.itemFromModal);
					// this.itemTotalPopup = parseInt(this.itemTotalPopup) * parseInt(this.itemFromModal);
					
					// cusineDetailsDataArr.price = this.itemTotalPopup;

					this.updateItemToCart(itemData, typeIndex, cusineIndex, itemFrom);
				},
		updateItemToCart(cusineDataArr, typeIndex, cusineIndex, itemFrom) // itemFrom = 0=> from card, 1=>cart, 2=>modal, 3=>Remove Item  
		{	
			if((cusineDataArr.mealType == 2 || cusineDataArr.mealType == 3) && cusineDataArr.quantity < cusineDataArr.minorderquantity)
			{
				this.$confirm("Are you sure, you want to remove this item from cart. Minimum order quantity requires "+ cusineDataArr.minorderquantity+ " pax","Remove Item?","")
				.then(() => {
								this.arrayColumn = commonApi.arrayColumn;
								var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');

								this.updateItemToCartCommonApi = commonApi.updateItemToCartApi;
								this.updateItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItemsIDArr);
				})
				.catch(error => {
						cusineDataArr.quantity = cusineDataArr.minorderquantity;
						});
				// cusineDataArr.quantity = cusineDataArr.minorderquantity;
				
			}
			else
			{
				this.arrayColumn = commonApi.arrayColumn;
				var selectedAddOnItemsIDArr = this.arrayColumn(this.selectedAddOnItems, 'addOnItemID');

				this.updateItemToCartCommonApi = commonApi.updateItemToCartApi;
				this.updateItemToCartCommonApi(cusineDataArr, typeIndex, cusineIndex, itemFrom, selectedAddOnItemsIDArr);
			}
		},

		// updateItemToCartEvent(cusineDataArr)
		// {
		//     this.$analytics.logEvent('update_cart', { 
		//         userID: localStorage.getItem('userID'), 
		//         userName: localStorage.getItem('userName'),
		//         itemID: JSON.stringify(cusineDataArr.id),
		//         itemName: JSON.stringify(cusineDataArr.name),
		//         itemPrice: JSON.stringify(cusineDataArr.price),
		//         itemQuantity: JSON.stringify(cusineDataArr.quantity),
		//         itemMealType: JSON.stringify(cusineDataArr.meal_type),
		//         itemRestID: JSON.stringify(cusineDataArr.restaurantid),
		//         itemRestName: JSON.stringify(cusineDataArr.restaurantName),
		//     }, { 'debug_mode':true })
		// },

		// updateCartItemEvent(cusineDataArr)
		// {
		// 	this.$analytics.logEvent('update_cart', { 
		//     	userID: localStorage.getItem('userID'), 
		//     	userName: localStorage.getItem('userName'),
		//     	cartID: JSON.stringify(cusineDataArr.cartID),
		//     	cuisineID: JSON.stringify(cusineDataArr.cuisineID),
		//     	cuisineName: JSON.stringify(cusineDataArr.cuisineName),
		//     	itemQuantity: JSON.stringify(cusineDataArr.quantity),
		//     	itemPrice: JSON.stringify(cusineDataArr.price),
		//     	itemTotal: JSON.stringify(cusineDataArr.itemTotal),
		//     	itemMealType: JSON.stringify(cusineDataArr.meal_type),
		//     	itemRestID: JSON.stringify(cusineDataArr.restaurantid),
		//     	itemRestName: JSON.stringify(cusineDataArr.restaurantName),
		//   	}, { 'debug_mode':true })
		// },
	},

			directives: {
						'class-on-scroll': ClassOnScroll,
						"sticky": VueStickyDirective
						},
						
	components: {
					headerLayout,
					footerLayout, 
					carousel,
				//  VueDropdown, 
					VsaList,
					VsaItem,
					VsaHeading,
					VsaContent,
					VsaIcon,
					IntegerPlusminus,
					searchBoxPopup
				//  VueHorizontalList,
					},
}
</script>

<template>
	<div class="container-fuild m-0 p-0">
<headerLayout :cartDetailsHead="cartDetails" :cartItemsDetailsHead="cartItemsDetails" :isRestdetails="1"/>
<searchBoxPopup :restid="this.restDetails.restaurantID" />
	<!-- header layout -->
		<main>
			<div class="container customPadding">
				<div class="row" v-if="restDetails">
					<div class="col-md-8 col-sm-12 col-xs-12">
						<!-- <img :src="restDetails.image == null || restDetails.image == '' ? 'img' : restDetails.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="100" style="width:100px;height:100px; max-height:100px; padding: 0px 15px 10px 0; float: left; margin-right: 10px; object-fit:contain;" alt="thumb" class="lazy restLogo"> -->
						<img :src="restDetails.image" width="100" :style="restDetails.image == null || restDetails.image == '' ? 'display:none;' : 'display:block;'" 
							style="width:100px; max-height:100px; float: left; margin-right: 10px; object-fit:contain;" alt="thumb" class="lazy restLogo">
						<h5 class="restName">{{restDetails.restaurantName}}</h5>
						<p  style="color: #A4827D;" class="restTagLine">{{restDetails.restBranchName}}</p>
						<p class="restTagLine restTaglineP">{{restDetails.tagline}}</p>
						<p class="restMenuType"><i class="icon-food_icon_cloche fs1"></i><span v-for="(specialityCuisines,index) in restDetails.cuisineList" v-bind:key="specialityCuisines.cuisineID" >{{specialityCuisines.cuisineName}}<span v-if="index+1 < restDetails.cuisineList.length">, </span></span></p>
							<p class="time_rating" :style="restDetails.image == null || restDetails.image == '' ? 'margin-left:0px;' : 'margin-left:111px;'">
								<span class="time">
									<i class="icon_clock_alt"></i>
									{{restDetails.delivery_time}} Mins
								</span>
								<span class="rating score CateresScore" v-if="restDetails.rating > 0">
								<strong>{{restDetails.rating}}</strong>
								<!-- <span class="rating_count">({{restDetails.ratingCount}} Ratings)</span> -->
								<a style="cursor:pointer;" v-on:click="allReviewsData && allReviewsData.length > 0 ?getAllTheReviews() : ''">
									<span style="text-decoration: underline;" class="rating_count">
										({{restDetails.ratingCount}} Ratings)
									</span>
								</a>
								</span>
						</p>
						<div class="container" :style="restDetails.image == null || restDetails.image == '' ? 'margin-left:0px;padding:0px;' : 'margin-left:92px;'">
							<div class="foodTypeModal align-center" :style="{background : foodType.background}" v-for="foodType in restDetails.foodType" v-bind:key="foodType.name">
								{{foodType.name}}
							</div>
						</div>
					</div>
					<div class="col-4 restBannerImg">
						<!-- <img :src="restDetails.bannerUrl == null || restDetails.bannerUrl == '' ? 'img' : restDetails.bannerUrl" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="270" height="160" alt="thumb" class="lazy restBanner"> -->
						<img :src="restDetails.bannerUrl" :style="restDetails.bannerUrl == null || restDetails.bannerUrl == '' ? 'display:none;' : 'display:block;' " width="270" height="160" alt="thumb" class="lazy restBanner">
					</div>
				</div>
				
				<section class="restCouponsSection" v-if="restDetails.discountCoupons.length > 0">
					<ul id="coupons">
						<li v-for="discCoupons in restDetails.discountCoupons" :key="discCoupons.couponID">
							<p v-if="discCoupons.discountType == 1">₹{{discCoupons.discountInRs}} off on min order of ₹{{discCoupons.minOrderValue}}</p>
							<p v-if="discCoupons.discountType == 2">{{discCoupons.discountPercent}}% off up to ₹{{discCoupons.maxDiscountCapping}}</p>
							<span>Use code {{discCoupons.couponCode}}</span>
						</li>
					</ul>
				</section>

			</div>
		<!-- /container -->

			<!-- /secondary_nav -->
		
			<nav class="secondary_nav sticky_horizontal"  v-class-on-scroll="{ class: 'is_stuck', offset: 0 }">
				<div class="container">
					<ul id="secondary_nav">
						<scrollactive class="my-nav" active-class="activeMenu" :offset="148">
							<!-- <li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-1">Last Ordered</a></li> -->
							<!-- <li><a class="list-group-item list-group-item-action scrollactive-item" href="#section-2">Starter</a></li> -->
							<li v-for="item in list" v-bind:key="item.categoryid"><a class="list-group-item list-group-item-action scrollactive-item" v-bind:href="'#section'+item.categoryTitle.substring(0,6).trim()">{{item.categoryTitle}}</a></li>
							<!-- <li><a class="list-group-item list-group-item-action" href="#section-4">Beverages</a></li> -->
							<!-- <li><a class="list-group-item list-group-item-action" href="#section-5"><i class="icon_chat_alt"></i>Reviews</a></li> -->
							<li v-if="allReviewsData && allReviewsData.length > 0" style="cursor:pointer;" class="reviewcl"><a class="list-group-item list-group-item-action  border border-light rounded" v-on:click="allReviewsData && allReviewsData.length > 0 ?getAllTheReviews() : ''" ><i class="icon_chat_alt"></i> Reviews</a></li>
						</scrollactive>
					</ul>
				</div>
				<span></span>
			</nav>

		<!-- /secondary_nav -->

			<div class="bg_gray mainDivCuisineDetails">
			<div class="container margin_detail">
				<div class="row">
					<!-- <div class="col-lg-8 list_menu"> -->
					<div id="list_menu_div" class="col-lg-8 list_menu" :class="$isExtMob == 1 ? 'mobListExt' : ''">
						<!-- API SECTION -->
						<!-- <VShimmerLine width="20" /> -->
						<div v-if="Shimmer == 0">
							<section>
							<div class="row">
								<div class="col-md-9 col-lg-9 col-xl-9 sectionText" style="width:50%!important;margin-bottom:0px;">
									<h4><VShimmerLine width="40" /></h4>
								</div>
							</div>
							<div class="row">
								<div class="col-md-6" v-for="n in 10" v-bind:key="n">
									<div class="strip">
									<a class="menu_item modal_dialog">
										<!-- <div class="discountTag" v-if="itemData.discountprice > 0">
											<img src="../../templateAssests/img/ckgallery/discount_tag.png" alt="thumb" height="20" width="65">
											<span class="discountText">{{itemData.discountprice}}% off</span>
										</div> -->
										<!-- <span class="ribbon1 off">10% off</span> -->
										<figure><VShimmerBlock width="98" heightPx="75" /></figure>
										<VShimmerLine width="16" heightPx="24" style="position: absolute;bottom:43px;right:20px;" />
										<h3><VShimmerLine width="40" /></h3>
										<p style="margin-top:15px;" class="two-line-text"><VShimmerLine width="80" /></p>
										<p style="margin-bottom:15px;" class="two-line-text"><VShimmerLine width="80" /></p>
										<VShimmerLine width="20" />
										<br>
										<VShimmerLine width="8" />
									</a>
									</div>
								</div>
								
							</div>
							<!-- /row -->
						</section>
						<!-- /section -->
						</div>
						<div class="row" v-if="mealTypeDisplayName != ''">
							<div class="col-8">
								<ul id="mealType_nav">
									<li ><a class="list-group-item list-group-item-action active">{{mealTypeDisplayName}}<a :href="$router.resolve({ name: 'restaurantDetailByShort', params: { id:restaurantIdentifier } }).href" class="icon_close removeMealTab"></a></a></li>
								</ul>
							</div>
							<div class="col-4">
								<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0 && mealType != 'corporate_meal'" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
											<!-- <vue-picker-option value="Halal"><div class="foodType halalFood align-center">
												H
											</div>Halal</vue-picker-option> -->
										</vue-picker>
							</div>
						</div>

						<!-- <div class="preBuyCouponsSection" v-if="restDetails.preBuyVouchers.length > 0"> -->
						<div class="preBuyCouponsSection"  v-if="restDetails.preBuyVouchers.length > 0 && restDetails.restType == 3 && mealType != 'corporate_meal'">
							<h3 class="preBuyTitle">Pre-Buy Voucher</h3>

							<carousel :autoplay="false" :navText="navIcons" :responsive="{0:{items:1.2, nav:false, loop:restDetails.preBuyVouchers.length > 1 ? true : false},600:{items:1.66,nav:true, loop:restDetails.preBuyVouchers.length > 2 ? true : false},1200:{items:2.84,nav:true, loop:restDetails.preBuyVouchers.length > 2 ? true : false},1800:{items:3,nav:true,loop:restDetails.preBuyVouchers.length > 4 ? true : false}}" :nav="true" :dots="false" :margin="5">
								<div v-on:click="getPreBuyVoucherDetails(preBuyVouchers)" v-for="preBuyVouchers in restDetails.preBuyVouchers" v-bind:key="preBuyVouchers.voucherID">
									<div v-if="preBuyVouchers.templateType == 1">
										<div class="preBuyImage" :style="{background : preBuyVouchers.color}">
											<div class="Voucheropacity-mask">
												<div class="voucherDetails">
													<img class="preBuyImageTempalet1" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image">
													<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
													<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
													<a class="viewRestBtnAnch" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
														<button class="btn_1 viewRestBtn view_details">Buy Now</button>
													</a>
												</div>
											</div>
										</div>
									</div>

									<div v-if="preBuyVouchers.templateType == 2">
										<div class="preBuyImage" :style="{ backgroundImage: 'url(' + preBuyVouchers.image + ')' }">
											<div class="Voucheropacity-mask">
												<div class="voucherDetails">
													<!-- <img class="preBuyImageTempalet1" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image"> -->
													<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
													<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
													<a class="viewRestBtnAnch" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
														<button class="btn_1 viewRestBtn view_details">Buy Now</button>
													</a>
												</div>
											</div>
										</div>
									</div>

									<div v-if="preBuyVouchers.templateType == 3">
										<div class="preBuyImage" :style="{ backgroundImage: 'url(' + preBuyVouchers.image + ')' }">
											<!-- <div class="Voucheropacity-mask">
												<div class="voucherDetails">
													<img class="preBuyImageTempalet1" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image">
													<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
													<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
													<a class="viewRestBtnAnch" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
														<button class="btn_1 viewRestBtn view_details">Buy Now</button>
													</a>
												</div>
											</div> -->
										</div>
									</div>

									<!-- <div class="item_version_2">
										<router-link :to="{ name: 'restaurantCollections', params: { type:'Banner' ,id: bannerDetails.bannerID} }">
											<figure class="cards">
												<img class="carouselBannerImg" :src="bannerDetails.bannerImage == null || bannerDetails.bannerImage == '' ? 'img' : bannerDetails.bannerImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="350" height="350">
												
											</figure>
										</router-link>
									</div> -->
								</div>
							</carousel>

							<!-- <carousel :autoplay="true" :navText="navIcons" :responsive="{0:{items:2, nav:false},600:{items:3,nav:true},1200:{items:4,nav:false},1800:{items:5,nav:false}}" :dots="false" :margin="5">
										<div class="preBuyCard" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)" v-for="preBuyVouchers in restDetails.preBuyVouchers" v-bind:key="preBuyVouchers.voucherID">
											<div class="preBuyTemplate templateType1" v-if="preBuyVouchers.templateType == 1">
												<div class="preBuyImage" :style="{background : preBuyVouchers.color}">
														<div class="Voucheropacity-mask">
														<div class="voucherDetails">
															<img class="preBuyImageTempalet1" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image">
															<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
															<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
															<a class="viewRestBtnAnch" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
																<button class="btn_1 viewRestBtn view_details">Buy Now</button>
															</a>
														</div>
														</div>
												</div>
											</div>

											<div class="preBuyTemplate templateType2" v-if="preBuyVouchers.templateType == 2">
												<div class="preBuyImage" :style="{ backgroundImage: 'url(' + preBuyVouchers.image + ')' }">
													<div class="Voucheropacity-mask">
														<div class="voucherDetails">
															<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
																<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
															<a class="viewRestBtnAnch" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
																<button class="btn_1 viewRestBtn view_details">Buy Now</button>
															</a>
														</div>
													</div>
												</div>
											</div>

											<div class="preBuyTemplate templateType3" v-if="preBuyVouchers.templateType == 3">
												<div class="preBuyImage" :style="{ backgroundImage: 'url(' + preBuyVouchers.image + ')' }">
													
												</div>
											</div>
										</div>
							</carousel> -->
							
							<!-- <div class="row">
								<div class="preBuyCard col-6" v-for="preBuyVouchers in restDetails.preBuyVouchers" :key="preBuyVouchers.voucherID"> -->
									<!-- <div class="preBuyTemplate templateType1" v-if="preBuyVouchers.templateType == 1" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
										<div class="templateBackground" :style="{background : preBuyVouchers.color}" style="height:166px;border-radius:8px;"></div>
										<div class="inImage">
											<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
											<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
											<a v-on:click="getPreBuyVoucherDetails(preBuyVouchers)" class="btn_1 mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:11px 10px;font-size:14px;">Buy Now</a>
											<img class="preBuyImageTempalet1" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image" height="166">
										</div>
									</div> -->
									<!-- /template 1 -->

									<!-- <div class="preBuyTemplate templateType2" v-if="preBuyVouchers.templateType == 2" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
										<img class="preBuyImage" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="166">
										<div class="inImage">
											<h5 class="preBuyCouponTitle">{{preBuyVouchers.voucherTitle}}</h5>
											<p class="PreCouponDesc two-line-text">{{preBuyVouchers.shortDesc}}</p>
											<a v-on:click="getPreBuyVoucherDetails(preBuyVouchers)" class="btn_1 mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:11px 10px;font-size:14px;">Buy Now</a>
										</div>
									</div> -->
									<!-- template 2 -->
									<!-- <div class="preBuyTemplate templateType3" v-if="preBuyVouchers.templateType == 3" v-on:click="getPreBuyVoucherDetails(preBuyVouchers)">
										<img class="preBuyImage" :src="preBuyVouchers.image == null || preBuyVouchers.image == ''? 'img' : preBuyVouchers.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" height="166">
									</div> -->

								<!-- </div>
							</div> -->
						</div>
						<section v-bind:id="'section'+item.categoryTitle.substring(0,6).trim()" v-for="(item, typeIndex) in list" v-bind:key="item.categoryid">
							<div class="row">
								<div class="col-md-9 col-lg-9 col-xl-9 sectionText" :class="{'col-sm-12 col-xs-12': typeIndex > 0}" :style="typeIndex == 0 ? 'width:50%!important;' : ''">
								<!-- <div class="col-md-9 col-lg-9 col-xl-9 col-sm-12 col-xs-12 sectionText"> -->
									<h4>{{item.categoryTitle}}</h4>
									<p class="corporateMealDiscount" v-if="mealType == 'corporate_meal'">
										<span v-if="item.isFreeMeal == 1 && item.discountType == 0">Buy any 1 item for free</span>
										<span v-if="item.discountType == 1">Get ₹{{item.discountInRs}} off on your meal</span>
										<span v-if="item.discountType == 2 && item.maxDiscountCapping > 0">Get {{item.discountPercent}}% upto ₹{{item.maxDiscountCapping}} on your meal</span>
									</p>
								</div>
								<div class="col-md-3 col-lg-3 col-xl-3 align-right dishTypes" style="width:50%!important;" v-if="typeIndex == 0 && mealTypeDisplayName == ''">
									<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0 && mealType != 'corporate_meal'" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
											<!-- <vue-picker-option value="Halal"><div class="foodType halalFood align-center">
												H
											</div>Halal</vue-picker-option> -->
										</vue-picker>
								</div>
							</div>
							<!--  For Nearbyrest, team meeting etc. -->
							<div class="row" v-if="Shimmer == 1 && mealType != 'corporate_meal'">
								<div class="col-md-6" v-for="(itemData, cusineIndex) in item.data" v-bind:key="itemData.id" v-on:click="getCusineDetails(itemData, typeIndex, cusineIndex)">
									<div class="strip strip_mobile">
									<a class="menu_item modal_dialog mobile_item" v-b-modal.modal-viewDish>
										<div class="discountTag" v-if="itemData.discountprice > 0">
											<img src="../../templateAssests/img/ckgallery/discount_tag.png" alt="thumb" height="20" width="65">
											<span class="discountText">{{itemData.discountprice}}% off</span>
										</div>
										<!-- <span class="ribbon1 off">10% off</span> -->
										<figure :style="itemData.thumbnail == null || itemData.thumbnail == '' ? 'display:none;' : 'display:block;'"><img :src="itemData.thumbnail == null || itemData.thumbnail == '' ? 'img' : itemData.thumbnail" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" alt="thumb" width="90" class="cardImage"></figure>
										<span  v-if="itemData.isInStock == 0" class="outOfStockSpan outOfStockSpan_mobile">OUT OF STOCK</span>
										<div class="addBtnPostion addBtnPostion_mobile" v-else>
											<span v-if="itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0" v-on:click="addItemToCart(itemData, typeIndex, cusineIndex, 0)" class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
											<!-- updateItemToCartEvent(itemData) -->
											<integer-plusminus v-else-if="itemData.quantity > 0" v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 0)" v-model="itemData.quantity" :min="itemData.mealType == 2 || itemData.mealType == 3 ? itemData.minorderquantity : 0" :max="mealType == 'corporate_meal' ? itemData.minorderquantity : 10000" :step="1">
												<!-- updateItemToCartEvent(itemData) -->
												<!-- updateItemToCartEvent(itemData) -->
											</integer-plusminus>
											<div v-if="itemData.isCustomisable=='1'">
												<span  v-if=" itemData.quantity == 0"  class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
												<div class="customisableSpan align-center">Customisable</div>
											</div>
										</div>
										<!-- if isCustomisable show addon modal -->
										<h3>{{itemData.name}}</h3>
										<div class="itemPriceDiv_mobile" v-if="itemData.mealType == 2 || itemData.mealType == 3">₹{{itemData.price}} <span style="color:#777!important;font-size:10px;">per pax | </span></div>
										<div v-else class="dishPrice_mobile">
											₹{{itemData.price}} | 
										</div>
										<p class="two-line-text desc" v-if="itemData.description !='' || itemData.description != null">{{itemData.description}}</p>
										<div class="dishServings">Serves {{itemData.serves}} </div>

										<!-- {{itemData.type[0].name.charAt(0)}} -->
											<div class="foodType align-center" :style="{background : mealType.background}" v-for="mealType in itemData.foodType" v-bind:key="mealType.name" :id="mealType.name"  v-b-tooltip.hover="mealType.name" :title="mealType.name">
												{{mealType.shortCode}}
											</div>
											<p class="one-line-text desc_mobile" v-if="itemData.description !='' || itemData.description != null">{{itemData.description}}</p>
											<!-- <div class="foodType veganFood align-center">
												VE
											</div>
											<div class="foodType vegFood align-center">
												V
											</div>
											<div class="foodType halalFood align-center">
												H
											</div> -->
										<div class="lead_time" v-if="itemData.mealType == 2 || itemData.mealType == 3">
												Order Notice: {{itemData.leadTime ? itemData.leadTime : 0}} Hours <span v-if="itemData.mealType == 2 || itemData.mealType == 3" class="minQty">| Minimum {{itemData.minorderquantity}} pax</span>
											</div>
										<div class="itemPriceDiv" v-if="itemData.mealType == 2 || itemData.mealType == 3">₹{{itemData.price}} <span style="color:#777!important;font-size:10px;">per pax</span></div>
										<div v-else class="dishPrice">
											₹{{itemData.price}} 
										</div>
									</a>
									</div>
								</div>
								
							</div>
							<!-- /row -->

							<!-- For corporate Meals -->
							<div class="row" v-if="Shimmer == 1 && mealType == 'corporate_meal'">
								<div class="col-md-6" v-for="(itemData, cusineIndex) in item.data" v-bind:key="itemData.id" v-on:click="getCusineDetails(itemData, typeIndex, cusineIndex)">
									<div class="strip strip_mobile">
									<a class="menu_item modal_dialog mobile_item" v-b-modal.modal-viewDish>
										<div class="discountTag" v-if="itemData.discountprice > 0">
											<img src="../../templateAssests/img/ckgallery/discount_tag.png" alt="thumb" height="20" width="65">
											<span class="discountText">{{itemData.discountprice}}% off</span>
										</div>
										<!-- <span class="ribbon1 off">10% off</span> -->
										<figure :style="itemData.thumbnail == null || itemData.thumbnail == '' ? 'display:none;' : 'display:block;'"><img :src="itemData.thumbnail == null || itemData.thumbnail == '' ? 'img' : itemData.thumbnail" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" alt="thumb" width="90" class="cardImage"></figure>
										<span  v-if="itemData.isInStock == 0" class="outOfStockSpan outOfStockSpan_mobile">OUT OF STOCK</span>
										<div class="addBtnPostion addBtnPostion_mobile" v-else>
											<span v-if="itemData.isAddable == 1 && itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0 && (item.isFreeMeal != 1 && item.discountType != 0)" v-on:click="addItemToCart(itemData, typeIndex, cusineIndex, 0)" class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
											<!-- addItemToCartEvent(itemData) -->
											<span v-if="itemData.isAddable == 0 && itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0 && (item.isFreeMeal != 1 && item.discountType != 0)" v-on:click="oneItemFreeAlert" class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
											<span v-if="itemData.isAddable == 0 && itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0 && (item.isFreeMeal == 1 && item.discountType == 0)" v-on:click="oneItemFreeAlert" class="btn_1 addBtn_mobile addBtn mb_5">PICK UP</span>
											<span v-if="isPickupDisabled == 0 && itemData.isAddable == 1 && itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0 && (item.isFreeMeal == 1 && item.discountType == 0)" v-on:click="freeMealCheckOut(itemData, typeIndex, cusineIndex, 0)" class="btn_1 addBtn_mobile addBtn mb_5">PICK UP</span>
											<span v-if="isPickupDisabled == 1 && itemData.isAddable == 1 && itemData.isCustomisable=='0' && itemData.quantity == 0 && itemData.isInStock != 0 && (item.isFreeMeal == 1 && item.discountType == 0)" class="btn_1 addBtn_mobile addBtn mb_5">PICK UP</span>
											<span  v-if="itemData.quantity > 0 && (item.isFreeMeal == 1 && item.discountType == 0)" v-on:click="updateItemToCart(itemData, typeIndex, cusineIndex, 3)" class="btn_1 addBtn_mobile addBtn mb_5">REMOVE</span>
											<!-- updateItemToCartEvent(itemData) -->
											<integer-plusminus v-else-if="itemData.quantity > 0 && item.discountType > 0" v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 0)" v-model="itemData.quantity" :min="itemData.mealType == 2 || itemData.mealType == 3 ? itemData.minorderquantity : 0" :max="mealType == 'corporate_meal' ? itemData.minorderquantity : 10000" :step="1">
											<!-- updateItemToCartEvent(itemData) , updateItemToCartEvent(itemData) -->
											</integer-plusminus>
											<div v-if="itemData.isCustomisable=='1'">
												<span  v-if=" itemData.quantity == 0"  class="btn_1 addBtn_mobile addBtn mb_5">+ADD</span>
												<div class="customisableSpan align-center">Customisable</div>
											</div>
										</div>
										<!-- if isCustomisable show addon modal -->
										<h3>{{itemData.name}}</h3>
										<p class="two-line-text" v-if="itemData.description !='' || itemData.description != null">{{itemData.description}}</p>
										<div class="dishServings">Serves {{itemData.serves}} </div>

										<!-- {{itemData.type[0].name.charAt(0)}} -->
											<div class="foodType align-center" :style="{background : mealType.background}" v-for="mealType in itemData.foodType" v-bind:key="mealType.name" :id="mealType.name"  v-b-tooltip.hover="mealType.name" :title="mealType.name">
												{{mealType.shortCode}}
											</div>
											<!-- <div class="foodType veganFood align-center">
												VE
											</div>
											<div class="foodType vegFood align-center">
												V
											</div>
											<div class="foodType halalFood align-center">
												H
											</div> -->
										<div class="lead_time" v-if="itemData.mealType == 2 || itemData.mealType == 3">
												Order Notice: {{itemData.leadTime ? itemData.leadTime : 0}} Hours <span v-if="itemData.mealType == 2 || itemData.mealType == 3" class="minQty">| Minimum {{itemData.minorderquantity}} pax</span>
											</div>
										<div class="itemPriceDiv" v-if="itemData.mealType == 2 || itemData.mealType == 3">₹{{itemData.price}} <span style="color:#777!important;font-size:10px;">per pax</span></div>
										<div v-else class="dishPrice">
											<span v-if="itemData.price > 0 ">₹{{itemData.price}}</span> 
												<!-- if meal type isCorporate Meal -->
											<span style="color:#777!important;font-size:10px;">
												<!-- if Show selling price -->
												<span v-if="itemData.price > 0 && itemData.showSellingPrice == 1 && (itemData.dishPrice > 0 && itemData.dishPrice != null) && itemData.price != itemData.dishPrice" style="text-decoration:line-through;">₹{{itemData.dishPrice}}</span>
												<!-- Packaging price -->
												<!-- <span v-if="itemData.packingCharges > 0 && itemData.packingCharges != null"> | Packing Charges + ₹{{itemData.packingCharges}}</span> -->
											</span>
												<!-- if meal type isCorporate Meal -->
										</div>
									</a>
									</div>
								</div>
								
							</div>
						</section>
						<!-- /section -->

								<!-- if  no items available for food type -->
						<div v-if="list.length == 0 && Shimmer == 1 && mealTypeDisplayName == ''" class="align-right dishTypes" style="width:98%!important;">
									<vue-picker placeholder="Select Diet" v-if="allFoodTypes.length !== 0 && mealType != 'corporate_meal'" v-model="selectFoodType" @input="changeFoodType" style="max-width:170px!important;margin-left:auto;">
											<vue-picker-option value="0"><div class="foodType align-center" style="background:#a4827d;">
												A
											</div> All</vue-picker-option>
											<vue-picker-option v-for="foodTypes in allFoodTypes" v-bind:key="foodTypes.typeID" :value="foodTypes.typeID">
												<div class="foodType align-center" :style="{background : foodTypes.color}">
													{{foodTypes.shortCode}}
												</div>
												{{foodTypes.foodTypeName}}
											</vue-picker-option>
											<!-- <vue-picker-option value="Halal"><div class="foodType halalFood align-center">
												H
											</div>Halal</vue-picker-option> -->
										</vue-picker>
								</div>
								<!-- if  no items available for food type -->

								<!-- /  if corpBranchId is empty -->
									<div v-if="list.length <= 0">
										<div class="container">
											<div class="noResultsMainDiv align-center row">
												<div class="col-12 align-center" style="margin: auto;padding: 10px;">
													<h1 style="font-size:25px;">Sorry, no results found</h1>
													<span style="color:#777;font-size:14px;">Your area is currently not serviceable</span>
												</div>
												<!-- <div class="col-6 align-left">
													<img src="../../templateAssests/img/ckgallery/no_results.png" width="150">
												</div> -->
											</div>
										</div>
									</div>
									<!-- /list no results end -->
								
					</div>
					<!-- /col -->
					<div class="col-lg-4" id="sidebar_fixed" data-v-sticky-container>
					<!-- <div class="col-lg-4 sidebar_fixed_mobile" id="sidebar_fixed" data-v-sticky-container> -->
						<div v-sticky="options">
						<div class="box_order mobile_fixed" v-if="!cartDetails.length">
							
							<div class="main mainCart">
								<div class="cartItems align-center">
									<img src="../../templateAssests/img/ckgallery/emptybag.png">
									<h4 class="noItem">No Item in Cart</h4>
								</div>
								
								
							</div>
						</div>
						<!-- /box_order -->
						<div class="box_order mobile_fixed" v-else>
							<div class="head">
								<div class="row">
									<!-- <div class="col-md-2 col-lg-2 col-xl-2">
										<img  :src="cartDetails[0].restImage == null || cartDetails[0].restImage == '' ? 'img' : cartDetails[0].restImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" alt="thumb" class="lazy">
									</div>
									<div class="col-md-10 col-lg-10 col-xl-10">
										<div  class="restaurantsDetails">
											<h5 v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restName}}</h5>
											<h5 v-else>Restaurant</h5>
										</div>
										<div  class="locationDetails">
											<p v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restaurantBranchName}}</p>
										</div>
									</div> -->
										<div>
										<img :src="cartDetails[0].restImage == null || cartDetails[0].restImage == '' ? 'img' : cartDetails[0].restImage" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="55" height="55" style="width:55px; max-height:55px;object-fit:cover;float: left;margin-right: 10px;" alt="thumb" class="lazy">
											<h5 v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restName}}</h5>
											<h5 v-else>Restaurant</h5>
											<p v-if="cartDetails != undefined && cartDetails[0].length !== 0">{{cartDetails[0].restaurantBranchName}}</p>
									</div>
									<!-- /col -->
								</div>
							</div>
							<!-- /head -->
							<div class="main mainCart">
								<div class="cartItems">
									<h4>Cart Items 
										<small v-if="cartDetails != undefined && cartDetails[0].length !== 0">({{cartDetails[0].cardcount}})</small>
										<small v-else>(0)</small>
									</h4>
								</div>
								<div v-if="cartItemsDetails != undefined" class="cartTotalItemDetails">
									<div class="row cartRow" v-if="ItemsInCart.quantity > 0" v-for="ItemsInCart in cartItemsDetails" v-bind:key="ItemsInCart.cartID" style="margin-bottom:10px;">
										<div class="col-md-8 col-lg-8 col-xl-8 dishName">
											<h5>{{ItemsInCart.cuisineName}} <div class="foodTypeContent align-center" :style="{background : foodType.background}" v-for="foodType in ItemsInCart.type" v-bind:key="foodType.name"  :id="foodType.name"  v-b-tooltip.hover="foodType.name" :title="foodType.name"></div></h5>
											<!-- <a v-if="ItemsInCart.isCustomisable == 1" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a> -->
											<a v-if="ItemsInCart.isCustomisable == 1" @click="addOnsModal(ItemsInCart.cartID, ItemsInCart.cuisineID, ItemsInCart.restBranchID)" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on</p></a>
										</div>
										<!-- / col -->
										<div class="col-md-2 col-lg-2 col-xl-2 dishDetails align-right">
											<!-- <input style="border-radius:18px;" type="number" class="form-control" value="1"/> -->
											<integer-plusminus  v-on:click.native="updateItemToCart(ItemsInCart, 0, 0, 1)"  v-model="ItemsInCart.quantity" :min="0" :max="ItemsInCart.mealType == -2 ? ItemsInCart.quantity: 10000" :step="1">
												<!-- updateCartItemEvent(ItemsInCart) -->
											<!-- <integer-plusminus  v-on:click.native="updateItemToCart(ItemsInCart, 0, 0, 1)"  v-model="ItemsInCart.quantity" :min="0" :max="100" :step="1"> -->
												<!-- {{ItemsInCart.quantity}} -->
											</integer-plusminus>
										</div>
										<!-- / col -->
										<div class="col-md-2 col-lg-2 col-xl-2 dishDetails align-right">
											<div class="dishRate">₹{{ItemsInCart.itemTotal}}</div>
										</div>
										<!-- / col -->
										<!-- <hr class="hrBorder"> -->

										<!-- <div class="col-md-8 col-lg-8 col-xl-8 dishName">
											<h5>Kung Pizza</h5>
											<div class="dishServings">Serve 1 </div>
												<div class="foodType veganFood align-center">
													VE
												</div>
												<div class="foodType vegFood align-center">
													V
												</div><br>
											<a href="#" class="customAnch customizeAddons"><p style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-bottom:0px;font-size:12px;color:#F3766A;">Customize add on:Burnt chilli, sauce and other.</p></a>
										</div> -->
										<!-- / col -->
										<!-- <div class="col-md-4 col-lg-4 col-xl-4 dishDetails align-right"> -->
											<!-- <input style="border-radius:18px;" type="number" class="form-control" value="1"/> -->
											<!-- <integer-plusminus :min="1" :max="100" :step="1">
												
											</integer-plusminus>
											<span class="dishRate"><strong>₹380</strong></span>
										</div> -->
										<!-- / col -->
										<!-- <hr class="hrBorder"> -->
									</div>
									<!-- /row  -->
								</div>
								<!-- endIf cartItems -->
								<ul class="clearfix cartPrice" v-if="cartDetails[0].totalCorporateSubsidy > 0">
									<li>Corporate subsidy<span>- ₹{{cartDetails[0].totalCorporateSubsidy}}</span></li>
									<!-- <li><u id="tax">Tax</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].tax_amount}}</span></li>
									<li><u id="otherCharges">Other Charges</u><sup style="color:#f3766a;">*</sup><span>₹{{cartDetails[0].totalOcharge}}</span></li> -->
								</ul>

								<!-- / Tooltips for charges -->
									<!-- <b-tooltip target="tax" triggers="hover" placement="right">
									<div class="row">
										<div class="col-8 align-left mb_5">
											<span>CGST</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].cgst}}</span>
										</div>
										<div class="col-8 align-left">
											<span>SGST</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].sgst}}</span>
										</div>
									</div>
								</b-tooltip>
								<b-tooltip target="otherCharges" triggers="hover"  placement="right">
									<div class="row">
										<div class="col-8 align-left mb_5">
											<span>Delivery Charges</span>
										</div>
										<div class="col-4 align-right">
											<span>₹{{cartDetails[0].totaldcharge}}</span>
										</div>
										<div class="col-9 align-left">
											<span>Restaurants Charges</span>
										</div>
										<div class="col-3 align-right">
											<span>₹{{cartDetails[0].totalrcharge}}</span>
										</div>
									</div>
								</b-tooltip> -->
								<!-- / Tooltips for charges -->

								<div class="row opt_order">
									<ul class="totalPrice">
									<li class="total">Total<span>₹{{cartDetails[0].totalitemprice}}</span></li>
									<span class="extraCharges">Extra charges may apply</span>
									</ul>
									
								</div>
								
								<div class="btn_1_mobile">
									<!-- <a @click="getCheckOutAccess" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a> -->
									<!-- <a href="/checkOut" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a> -->
									<a @click="getCheckOutAccess()" class="btn_1 full-width mb_5 disabledBtn" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;">Checkout</a>
								</div>
							</div>
						</div>
						<!-- /box_order -->
						<!-- <div class="btn_reserve_fixed onlyMobile mobileBasketDiv" :style="cartDetails.length <= 0 ? 'display:none!important;' : 'display:block;'"> -->
						<div id="mobile_basket_div" class="btn_reserve_fixed onlyMobile mobileBasketDiv" :class="$isExtMob == 1 ? 'mobBasketExt' : ''" :style="cartDetails.length <= 0 ? 'display:none!important;' : 'display:block;'">
							<div class="row">
								<div class="col-8 cartItemsCharges" v-if="cartDetails.length > 0">
									<p class="cartItemsMob">{{cartItemsDetails.length}} item in a cart | ₹
										{{cartDetails[0].totalitemprice}}
										</p>
									<p class="cartItemsCharges">Extra Charges may apply</p>
								</div>
								<div class="col-4 viewCartItems">
									<!-- <a href="/checkOut"><button class="btn">View Cart</button></a> -->
									<a @click="getCheckOutAccess()"><button class="btn">View Cart</button></a>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
				<!-- /row -->
			</div>
			<!-- /container -->
		</div>
		<!-- /bg_gray -->

		<div class="container fssaiSection align-center" v-if="licenseNO != ''">
			<div class="fssaiLogo">
				<img src="../../templateAssests/img/ckgallery/fssai.png" height="35" width="70" alt="fssai">
			</div>
			<div class="fssaiLicNo">
				<span>License No. {{licenseNO}}</span>
			</div>
		</div>
		<!-- /container -->

	</main>
	<!-- /main -->

	<!-- Modal item order -->
	<b-modal id="modal-viewDish" scrollable :footer-bg-variant="footerBgVariant" v-if="cusineDetailsDataArr && cusineModalShow === true" size="md" centered>
	<div class="container" style="max-width:100%!important;padding:0px;">
		<div>
				<img :style="cusineDetailsDataArr.image == null || cusineDetailsDataArr.image == '' ? 'display:none;' : 'display:block;'" :src="cusineDetailsDataArr.image == null || cusineDetailsDataArr.image == ''? 'img' : cusineDetailsDataArr.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
				<!-- <div  class="menuDetails"> -->
					<h5>{{cusineDetailsDataArr.name}}</h5>
					<p style="color:#F68F30;margin-bottom:5px;">{{cusineDetailsDataArr.restaurantName}}</p>
					<p class="menuPrice">₹{{cusineDetailsDataArr.price}} | Serves {{cusineDetailsDataArr.serves}}</p>
					<div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusineDetailsDataArr.foodType" v-bind:key="mealType.name">
						{{mealType.name}}
						<!-- {{mealType.name.replace(/,/g, "")}} -->
					</div>
					<!-- <div class="foodTypeModal halalFood align-center">
						Halal
					</div>
					<div class="foodTypeModal veganFood align-center">
						Vegan
					</div>
					<div class="foodTypeModal vegFood align-center">
						Vegetarian
					</div> -->
					<p style="margin-bottom:5px;">{{cusineDetailsDataArr.description}}</p>
				<!-- </div> -->
		</div>
		<!-- <div class="row">
			<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4" style="margin-right:20px;">
				<img :src="cusineDetailsDataArr.image" width="150" height="150" alt="thumb" class="lazy cuisineModalImg">
			</div>
			<div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
				<div  class="menuDetails">
					<h5>{{cusineDetailsDataArr.name}}</h5>
					<p style="color:#F68F30;">{{cusineDetailsDataArr.restaurantName}}</p>
					<p class="menuPrice">₹{{cusineDetailsDataArr.price}} | Serves {{cusineDetailsDataArr.serves}}</p>
					<div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusineDetailsDataArr.type" v-bind:key="mealType.name">
						{{mealType.name}} -->
						<!-- {{mealType.name.replace(/,/g, "")}} -->
					<!-- </div> -->
					<!-- <div class="foodTypeModal halalFood align-center">
						Halal
					</div>
					<div class="foodTypeModal veganFood align-center">
						Vegan
					</div>
					<div class="foodTypeModal vegFood align-center">
						Vegetarian
					</div> -->
					<!-- <p>{{cusineDetailsDataArr.description}}</p>
				</div>
			</div>
		</div> -->
		<!-- /Header row -->
		<br>
		<!-- <div> -->
			<div class="row">
				<div class="customizeSection col-md-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusineDetailsDataArr.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<!-- <div class="socialBtns align-right col-md-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div> -->
			</div>
			<hr v-if="cusineDetailsDataArr.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cuisineItemDetails != undefined && cusineDetailsDataArr.isCustomisable=='1'">
			<div  v-for="(addonItemsArr,index) in cuisineItemDetails" v-bind:key="addonItemsArr.addonGroupID">
				<vsa-item :forceActive="index == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} <span v-if="addonItemsArr.required == 0">(Optional)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						<!-- <div class="col-md-1">
							<div class="foodType modalExpandType vegFood align-center">
								V
							</div>
						</div> -->
						<div class="col-md-12">
							<!-- <b-form-checkbox
						value="accepted"
						unchecked-value="not_accepted"
						class="mb-3"
						checked
						plain
						>{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox
					> -->
						<b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedAddOnItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectAddons(cusineDetailsDataArr.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
						</b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
	</vsa-list>
		<!-- / Dropdown -->
		<!-- </div> -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
		<div class="w-100" v-if="(cusineDetailsDataArr.isFreeMeal != 1 && cusineDetailsDataArr.discountType != 0)">
			<div v-if="addOnError != ''" style="text-align:center;color:#fff;background:#f3766a;height:40px;margin-bottom:10px;padding:10px 0;">{{addOnError}}</div>
			<!-- <p class="float-left">Item total ₹{{cusineDetailsDataArr.price}}</p> -->
			<p class="float-left">Item total ₹{{itemTotalPopup}}</p>
			<div class="row float-right" v-if="cusineDetailsDataArr.isInStock != 0">
				<div class="col-md-6 align-right">
					<!-- <integer-plusminus v-on:click.native="updateItemToCart(itemData, typeIndex, cusineIndex, 2)" v-model="itemFromModal" :min="0" :max="100" :step="1">
					</integer-plusminus> -->
					<!-- <integer-plusminus v-model="itemFromModal" :min="cusineDetailsDataArr.minorderquantity" :max="100" :step="1">
					</integer-plusminus> -->
					<!-- updateItemToCartEvent(cusineDetailsDataArr) -->
					<integer-plusminus v-on:click.native="updateItemToCartModal(cusineDetailsDataArr,itemData, typeIndex, cusineIndex, 2)" v-model="itemFromModal" :min="cusineDetailsDataArr.mealType == 2 ? cusineDetailsDataArr.minorderquantity : 0" :max="mealType == 'corporate_meal' ? cusineDetailsDataArr.quantity: 10000" :step="1">
					<!-- <integer-plusminus v-on:click.native="updateItemToCartModal(cusineDetailsDataArr,itemData, typeIndex, cusineIndex, 2)" v-model="itemFromModal" :min="cusineDetailsDataArr.mealType == 2 || cusineDetailsDataArr.mealType == 3 ? cusineDetailsDataArr.minorderquantity : 0" :max="100" :step="1"> -->
					</integer-plusminus>
				</div>
				<div class="col-md-6">
				<!-- <b-button
				v-if="cusineDetailsDataArr.isCustomisable=='1'"
					size="sm"
					class="float-right viewDish-footer-btn"
						v-on:click="addItemToCart(cusineDetailsDataArr, cusineTypeIndex, cusineItemIndex, 1), addItemToCartEvent(cusineDetailsDataArr)"
				>
				
					<span v-if="cusineDetailsDataArr.isAddable == 0 && cusineDetailsDataArr.isCustomisable=='0' && cusineDetailsDataArr.quantity == 0 && cusineDetailsDataArr.isInStock != 0 && (cusineDetailsDataArr.isFreeMeal == 1 && cusineDetailsDataArr.discountType == 0)" v-on:click="oneItemFreeAlert">Add to Cart</span>
					<span>Add to Cart</span>
				</b-button> -->
				<b-button 
					v-if="cusineDetailsDataArr.isCustomisable=='1'" 
					size="sm" 
					class="float-right viewDish-footer-btn"
					v-on:click="checkAddonReq(cuisineItemDetails,cusineDetailsDataArr, cusineTypeIndex, cusineItemIndex, 1, 2)"
				>
					Add to Cart
				</b-button>
				<b-button
					v-else
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="cusineDetailsDataArr.isAddable == 1 && cusineDetailsDataArr.isCustomisable=='0' && cusineDetailsDataArr.quantity == 0 && cusineDetailsDataArr.isInStock != 0 && (cusineDetailsDataArr.isFreeMeal == 1 && cusineDetailsDataArr.discountType == 0)? freeMealCheckOut(cusineDetailsDataArr, typeIndex, cusineIndex, 0) : addItemToCart(cusineDetailsDataArr, cusineTypeIndex, cusineItemIndex, 1)"
				>
					<span v-if="cusineDetailsDataArr.isAddable == 0 && cusineDetailsDataArr.isCustomisable=='0' && cusineDetailsDataArr.quantity == 0 && cusineDetailsDataArr.isInStock != 0 && (cusineDetailsDataArr.isFreeMeal == 1 && cusineDetailsDataArr.discountType == 0)" v-on:click="oneItemFreeAlert">Add to Cart</span>
					<span v-else>Add to Cart</span>
				</b-button>
				</div>
			</div>
		</div>
		<div v-else>
		</div>

		</template>
	</b-modal> 
	<!-- /Modal item order -->


	<!-- Coupon details Modal -->
	<b-modal id="modal-couponDetails" v-if="preBuyVouchersObj != ''" hide-header hide-footer style="max-width:440px!important;"  centered>
		<div class="closeModalDiv align-right"><a class="customAnch"  @click="$bvModal.hide('modal-couponDetails')"><img class="mainLogoImg" src="../../templateAssests/img/ckgallery/cancelBtn.png"  width="17" height="17" alt=""></a></div>
			<h3 class="offerTitle">Offer Details</h3>
			<p class="couponTitle">{{preBuyVouchersObj.voucherTitle}}</p>
			<p class="couponDesc">{{preBuyVouchersObj.shortDesc}}</p>
				<div class="termsSection">
					<p class="modalTerms">Terms</p>
					<p class="modalTermsDesc">{{preBuyVouchersObj.termsOfUse}}</p>
					<!-- <p class="modalTermsDesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
					<p class="modalTermsDesc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p> -->
				</div>
				<!-- <form action="http://3.6.19.77/api/initVoucherOrder" name="payuform" method=POST> -->
				<form :action="$ApiUrl+'/initVoucherOrder'" name="payuform" method=POST>
				<!-- <form action="https://www.codetreasure.com/ck_API/public/api/initOrder" name="payuform" method=POST> -->
				<!-- <form action="/initVoucherOrder" name="payuform" method=POST> -->
				<input type="hidden" :value="userID" name="userID" id="userID">
				<input type="hidden" :value="preBuyVouchersObj.voucherID" name="voucherID" id="voucherID">
				<input type="hidden" :value="restDetails.restaurantID" name="restBranchID" id="restBranchID">
				<button type="submit" class="btn_1 full-width mb_5" style="background:#FFC60C;color:#000!important;padding:14px 24px;font-size:16px;">Pay ₹{{preBuyVouchersObj.sellingPrice}}</button>
				<!-- <a href="#" class="btn_1 full-width mb_5" style="color:#000!important;background:#FFC60C!important;padding:14px 24px;font-size:16px;margin: 30px 0px;">Pay ₹{{preBuyVouchersObj.sellingPrice}}</a> -->
				</form>
	</b-modal> 
<!-- / Coupon details Modal -->

<b-modal id="modal-cuisinePopup" scrollable  :footer-bg-variant="footerBgVariant" v-if="cusinieAddonsDetails && cusineAddonModalShow === true" size="md" centered>
		<div class="container" style="max-width:100%!important;padding:0px;">
			<!-- <p>Show cuisinePopup</p> -->
		<div>
			<img :style="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == '' ? 'display:none;' : 'display:block;'" :src="cusinieAddonsDetails.cuisineDetail.image == null || cusinieAddonsDetails.cuisineDetail.image == ''? 'img' : cusinieAddonsDetails.cuisineDetail.image" onerror="this.src='https://assets.cloudkitch.co.in/user_documents/Placeholder.png';" width="150" style="width:150px; max-height:200px; padding: 0px 15px 10px 0;float: left;margin-right: 10px;" alt="thumb" class="lazy cuisineModalImg">
			<h5>{{cusinieAddonsDetails.cuisineDetail.name}}</h5>
			<p style="color:#F68F30;margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.restaurantName}}</p>
			<p class="menuPrice">₹{{cusinieAddonsDetails.cuisineDetail.price}} | Serves {{cusinieAddonsDetails.cuisineDetail.serves}}</p>
			<div class="foodTypeModal align-center" :style="{background : mealType.background}" v-for="mealType in cusinieAddonsDetails.cuisineDetail.type" v-bind:key="mealType.name">
				{{mealType.name}}
			</div>
			<p style="margin-bottom:5px;">{{cusinieAddonsDetails.cuisineDetail.description}}</p>
			
		</div>
		
		<br>
			<div class="row">
				<div class="customizeSection col-6 col-lg-6 col-xl-6" style="float:left;">
					<p v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="color: #a38379;margin-bottom:0px; padding-top: 8px;">Customize</p>
				</div>
				<!-- <div class="socialBtns align-right col-6 col-lg-6 col-xl-6">
					<div class="socialBtnsDiv align-center" style="background:#4C6EF5;">
						<img src="../../templateAssests/img/ckgallery/fb.png" height="15" width="10" alt="fb">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#00E676;">
						<img src="../../templateAssests/img/ckgallery/whatsapp.png" height="15" width="14" alt="whatsapp">
					</div>
					<div class="socialBtnsDiv align-center" style="background:#A4827D;">
						<img src="../../templateAssests/img/ckgallery/share.png" height="15" width="15" alt="share">
					</div>
				</div> -->
			</div>
			<hr v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'" style="opacity:1;margin: 8px 0 12px 0;">
		<!-- / customizeSection -->

		<!--  Dropdown -->
		<vsa-list class="sauceSection" v-if="cusinieAddonsDetails.cuisineAddons != undefined && cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'">
			<div  v-for="(addonItemsArr, groupIndex) in cusinieAddonsDetails.cuisineAddons" v-bind:key="addonItemsArr.addonGroupID">
				<vsa-item :forceActive="groupIndex == 0 ? true : false">
				<vsa-heading>
					{{addonItemsArr.groupName}} <span v-if="addonItemsArr.required == 0">(Optional)</span>
				</vsa-heading>
	
				<vsa-content>
					<div class="row" v-for="addOnItems in addonItemsArr.data" v-bind:key="addOnItems.addOnItemID">
						
						<div class="col-md-12">
						<b-form-group v-slot="{ ariaDescribedby }">
						<b-form-checkbox-group
							id="checkbox-group-2"
							v-model="selectedAddonCartItems"
							:aria-describedby="ariaDescribedby"
							name="addOnItems" @change="selectCartModalAddons(cusinieAddonsDetails.cuisineDetail.price)">
							<b-form-checkbox checked="true" :value="addOnItems">{{addOnItems.title}} (+ ₹{{addOnItems.price}})</b-form-checkbox>
						</b-form-checkbox-group>
						</b-form-group>
						</div>
					</div>
				</vsa-content>
			</vsa-item>
			</div>
	</vsa-list>
		<!-- / Dropdown -->
		<!-- endiIF -->
	</div>
		<!-- /Container -->
		<template #modal-footer>
		<div class="w-100">
			<div v-if="addOnError != ''" style="text-align:center;color:#fff;background:#f3766a;height:40px;margin-bottom:10px;padding:10px 0;">{{addOnError}}</div>
			<p class="float-left">Item total ₹{{itemTotalCartModal}}</p>
			<div class="row float-right" v-if="cusinieAddonsDetails.isInStock != 0">
				<div class="col-6 align-right">
					<integer-plusminus v-on:click.native="updateCartDataFromModal(cusinieAddonsDetails.cuisineDetail)" v-model="itemFromCartModal" :min="0" :max="10000" :step="1">
					<!-- <integer-plusminus v-model="itemFromCartModal" :min="0" :max="100" :step="1"> -->
					</integer-plusminus>
				</div>
				<div class="col-6">
				<!-- <b-button
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="updateItemAddonModal(cusinieAddonsDetails.cuisineDetail, 1)"
				>
					Update Cart
				</b-button> -->
				<b-button
					v-if="cusinieAddonsDetails.cuisineDetail.isCustomisable=='1'"
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="checkAddonReq(cusinieAddonsDetails.cuisineAddons,cusinieAddonsDetails.cuisineDetail, 0,0,0, 1)"
				>
					Update Cart
				</b-button>
				<b-button
					v-else
					size="sm"
					class="float-right viewDish-footer-btn"
					v-on:click="updateItemAddonModal(cusinieAddonsDetails.cuisineDetail)"
				>
					Update Cart
				</b-button>
				</div>
			</div> 
		</div>
		</template>
	</b-modal> 

	<b-modal id="modal-allReviewsmodal" size="lg" hide-footer="true"  centered>
		<!-- <div class="row"><h1>Reviews  </h1> </div>
			<div class="review_card" v-for="allReviews in allReviewsData" :key="allReviews.FeedbackId">
				<div class="row">
					
					<div class="col-md-12 review_content">
						<div class="clearfix add_bottom_15">
							<span class="rating">{{allReviews.rating}}<small>/10</small> <strong>{{allReviews.userName}}</strong></span>
							<em>Published {{allReviews.date}}</em>
						</div>
						<p>{{allReviews.review}}</p>
					</div>
				</div>
				<div class="row reply" v-if="allReviews.allReplies!=''">
					
					<div class="col-md-10">
						<div class="review_content" v-for="allReplies in allReviews.allReplies" :key="allReplies.FeedbackId" >
							<strong v-if="allReplies.adminReply!=null">Reply from {{allReplies.adminReply}}</strong>
							<strong v-if="allReplies.userReply!=null">Reply from {{allReplies.userReply}}</strong>
							<em>Published {{allReplies.repliedDate}}</em>
							<p>{{allReplies.replyText}}</p>
						</div>
					</div>
				</div>
			</div> -->
	<template #modal-title>Reviews</template>
		<div class="section-services" v-for="allReviews in allReviewsData"
			:key="allReviews.FeedbackId">
			<div class="reviews">
				<section class="ordersSection">
					<div class="row">
						<div  class="col-md-12">
							<div class="strip">
								<div class="row menu_item">
									<div class="row">
										<div class="col-6 text-left">
											<p class="userName">{{ allReviews.userName }}</p>
										</div>
										<div class="col-6 text-right">
											<p class="fdbDate">{{ allReviews.date }}</p>
										</div>
										<div class="col-12" v-if="allReviews.rating > 0">
											<span v-for="index in 10" :key="index">
												<i
												v-if="index <= allReviews.rating"
												class="fa fa-star"
												style="margin-right: 5px; color: #ffc60c"
												></i>
												<i
												v-else
												class="fa fa-star"
												style="margin-right: 5px; color: #dadada"
												></i>
											</span>
										</div>
										<p class="starRating">{{ allReviews.rating }} of 10</p>
									</div>
									<div class="row">
										<p class="userReviewText">{{ allReviews.review }}</p>
									</div>
									<div class="row" v-if="allReviews.allReplies != ''">
										<hr style="margin-top: 10px; margin-bottom: 10px;">
										<div 
										class="replyBox"
										v-for="allReplies in allReviews.allReplies"
										:key="allReplies.FeedbackId"
										><div class="row">
											<div class="col-6 text-left">
												<p style="color: #979797; font-size: 11px; margin-bottom: 8px;">Reply from,</p>
											</div>
											<div class="col-6 text-right">
												<p class="fdbDate">{{ allReplies.repliedDate }}</p>
											</div>
										</div>
										<p class="adminReply" v-if="allReviews.restName != null"> {{ allReviews.restName }} - {{ allReviews.branchName }}</p>
										<p class="userReviewText">{{ allReplies.replyText }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</b-modal> 
<!-- / All Review  Modal -->

<footerLayout />
	<!-- footer layout -->
	</div>
	<!-- /root div -->
</template>
	
	<!-- Add "scoped" attribute to limit CSS to this component only -->
	<!-- SPECIFIC CSS -->
<style scoped src="../../templateAssests/css/listing.css"></style>
	<style scoped src="../../templateAssests/css/detail-page.css"></style>
<style scoped>
.customPadding
{
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}
main{
	background-color:#fff;
}
	.cards{
			max-width:350px;
			max-height:350px;
			border-radius:5px;
		}
		.cards span{
			border-radius: 0;
			right: 0;
			left: 15px;
			font-size: 12px;
			height: auto;
			width: 29%;
		}
		.searchCafes{
			padding-top: 12px;
		}
		.cateresCards{
			max-width:350px;
			max-height:370px;
		}
		.cateresCards img{
			border-radius:5px;
		}
		.cateresTitle{
			font-size: 18px;
			margin-bottom: 5px;
			margin-top:10px;
		}
		.cateresMenuType{
			margin-bottom: 5px;   
		}
		.restaurantsList{
			margin-top:40px;
		}
		.ribbon1{
				color: #fff;
				display: inline-block;
				position: absolute;
				bottom: 15px;
				left: 71px;
				font-weight: 500;
				border-radius: 3px;
				padding: 6px 8px 4px 8px;
				line-height: 1;
				font-size: 12px;
				font-size: 0.75rem;
				z-index: 9;
		}
		.ribbon1.off {
			background-color: #f3723b;
		}
		.secondary_nav {
			width: 100%;
			background-color: #fff;
			border-top: 1px solid #ededed;
			padding: 20px 0;
			position: relative;
		}
		.secondary_nav span {
			position: absolute;
			height: 7px;
			width: 100%;
			left: 0;
			bottom: 0;
			-webkit-box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
			-moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
			box-shadow: 0px 5px 5px 0px rgb(0 0 0 / 5%);
		}
		.bx{
			font-size: 24px;
			color:#212121;
		}
		.filter{
			display: block;
			width: 40px;
			height: 40px;
			line-height: 36px;
			border: 2px solid #f6f6f6;
			color: #74788d;
			border-radius: 4px;
			font-size: 24px;
			text-align:center;
		}
		.fssaiLogo{
		text-align: center;
		padding: 25px 25px 31px;
	}
	.fssaiLicNo{
		font-size: 16px;
		color:#777;
	}
		
		.vsa-list {
			--vsa-heading-padding: 0 0;
			--vsa-highlight-color: transparent; 
			--vsa-bg-color: transparent;
			--vsa-border-color: #eaeaea;
			--vsa-default-icon-size: 0.3;
		}
		.vsa-item__trigger{
			padding: 10px!important;
		}
		/* .vsa-item__trigger__content{
			font-size:14px;
		} */
		.modal-footer{
			background: #fff!important;
		}
		.viewDish-footer-btn{
			background-color: #ffc60c!important;
			color: #212121;
			/* font-weight: 600; */
			border: 1px solid #ffc60c;
			height: 32px;
			width:100px;
		}
		.owl-theme .owl-nav{
			border-radius:50%!important;
		}
		/* .activeMenu{
			color: #A4827D!important;
			background: #FFF!important;
			border:1px solid #A4827D;
		} */
		.modalExpandType{
			border-radius: 12px;
			width: 20px;
			height: 20px;
			color:#fff;
			display: inline-block;
			padding-top:2px!important;
			font-size: 11px;
		}
		.vue-picker{
			display: block;
			width: 100%;
			font-size: 0.9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #495057;
			background-color: #fff;
			border-radius: 5px;
		}
		.vue-picker__dropdown{
			border:1px solid #fff!important;
		}
		.vue-picker__opener:focus{
			box-shadow: none!important;
			border:transparent!important;
		}
		/* .dishTypes{
			width: 20%;
			margin-left: 0px;
		} */
		/* .item_version_2{
			min-height: 180px;
			min-width: 180px;
		} */
		.owl-item{
			min-height: 180px;
			min-width: 180px;
		}
		.strip figure img{
				z-index:0!important;
			}
		.secondary_nav.is_stuck{
			z-index: 1;
		}
		.addBtnPostion{
			position: absolute;
			bottom: 20px;
			right: 20px;
		}
	/* .int-pm {
			margin-left: 40px;
	} */
	ul#primary_nav li{
			display: inline-block;
			margin-right: 15px;
			border-radius: 30px;
		}
		ul#primary_nav li a{
			background: #E6E6E6;
			padding: 8px 15px;
			height: 40px;
		}
		ul#primary_nav li a.active{
			background: #F3766A;
			color: #fff!important;
			border:none!important;
		}
		.customisableSpan{
			/* position: absolute;
			right: 35px;
			bottom: 5px; */
			font-size: 10px;
			color:#A4827D;
			width: 80px;
		}
		.cardImage{
			object-fit: cover;
		}
		.cuisineModalImg{
			object-fit: contain;
		}
		.disabledBtn{
			background: #ced4da!important;
		}
		.disabledBtn:hover{
			background: #ced4da!important;
			color:#000;
		}
		.total{
			margin-left: 15px;
			margin-right: 15px;
			margin-bottom: 0px!important;
		}
		.totalPrice
		{
			margin-bottom: 6px;
		}
		.extraCharges{
			margin-left: 15px;
			font-size: 12px;
		}
		@media (max-width: 991px){
			.strip figure img{
				z-index:0!important;
			}
			.dishTypes{
				width: 100%!important;
				margin-left:0px!important;
			}
			.viewCartItems{
			padding-top: calc(var(--bs-gutter-x) / 2);
			padding-bottom: calc(var(--bs-gutter-x) / 2);
			padding-right: 0px;
			}
			.preBuyCard 
			{
				margin-bottom: 10px;
				cursor: pointer;
			}
		}
		.list_menu section{
			padding-top: 0px!important;
		}
		.menuPrice{
			margin-bottom:5px;
		}

		/* restaurant Details page Style */
		.restName
		{
			color:#222222;
			font-size: 17px;
			margin-bottom: 2px!important;
		}
		.restTagLine
		{
			margin-bottom: 2px!important;
			margin-bottom: 9px;
			font-size: 14px;
			color:#222222;
		}
		.restTaglineP
		{
			margin-left: 111px!important;
		}
		.restMenuType
		{
			margin-left: 111px!important;
			color: #777777;
			font-size: 14px;
			margin-bottom: 9px;
		}
		.restMenuType i{
			margin-right: 6px;
		}
		.time_rating i{
			margin-right: 6px;
		}
		.time_rating
		{
			font-size: 13px;
			color: #212121;
			margin-left: 111px;
		}
		.time
		{
			margin-right: 17px;
		}

		.CateresScore strong{
			padding: 0 0 0 0;
			margin-right: 5px;
		}
		.score{
			/* float:right!important; */
			font-size: 13px;
			color: #212121;
			margin-bottom: 8px;
		}
		.score strong:before{
			color:#FFC60C!important;
		}
		.rating_count
		{
			color: #626262;
			font-size: 13px;
		}

		.restBanner
		{
			border-radius: 5px;
			object-fit: cover;
		}
		.secondary_nav ul li a
		{
			border-radius: 0px;
			background: transparent;
			padding: 5px 8px;
		}
		.list-group-item
		{
			border: 0px;
		}
		.activeMenu
		{
			border:0px;
			border-bottom: 3px solid #A4827D;
			font-size: 15px;
			color: #A4827D!important;
			font-family: 'GothamMedium'!important;
		}
		.secondary_nav ul li a.active, .secondary_nav ul li a:hover
		{
			background: transparent;
			color: #A4827D;
		}
		.outOfStockSpan
		{
			position: absolute;
			right: 24px;
			bottom: 24px;
			font-size: 12px;
			color: red;
			font-size: 12px;
		}
		.dishPrice
		{
			font-size: 15px;
		}
		.minQty
		{
			color: #777777;
		}
		.itemPriceDiv
		{
			font-size: 15px;
		}
		.lead_time
		{
			/* padding: 9px 0 6px 0; */
			color: #777;
			font-size: 12px;
			margin: 2px 0;
		}
		ul#mealType_nav {
		margin-bottom: 16px;
		padding-left: 0px;
	}
	ul#mealType_nav li{
		display: inline-block;
		margin-right: 10px;
		border-radius: 30px;
	}
	ul#mealType_nav li a{
		background: #E6E6E6;
		padding: 0px 12px;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		z-index: 0;
	}
	ul#mealType_nav li a.active{
		background: #F3766A;
		color: #fff!important;
		border:none!important;
	}
	.removeMealTab
	{
		padding: 0!important;
		background: transparent!important;;
		color: #fff;
	}
	.cartRow
		{
			margin-left:0px;
			margin-right:0px;
		}
	.dishName, .dishDetails
	{
		padding: 0;
	}
	.dishCartPrice
	{
		padding: 0 5px;
	}
	.corporateMealDiscount
	{
		margin-bottom: 10px;
	}
	.corporateMealDiscount span{
		font-size: 12px;
		color: #a4827d;
	}
	.restCouponsSection ul#coupons li
	{
		border-left: 5px solid #F3766A;
	}
	.offerTitle
	{
		margin-bottom: 23px;
		font-size: 24px;
		color: #212121;
	}
	.couponTitle
	{
		margin-bottom: 12px;
		font-size: 17px;
		color: #F3766A;
	}
	.preBuyCouponsSection
	{
		margin-bottom: 25px;
	}
	.preBuyTitle
	{
		font-size: 24px;
		color: #212121;
		margin-bottom: 13px;
	}
	/* .preBuyCard
	{
		width: fit-content;
		margin-bottom: 20px;
	}
	.preBuyTemplate
	{
		cursor: pointer;
		min-width: 310px;
	}
	.preBuyImage
	{
		object-fit:cover;
		min-width: 100%;
		border-radius:8px;
	}
	.inImage
	{
		width: 94%;
		height: 100%;
		cursor: pointer;
		padding: 20px 16px;
		position: absolute;
		top: 0;
	}
	.preBuyCouponTitle
	{
		font-family: 'GothamMedium';
		color: #fff;
		font-size: 17px;
	}
	.PreCouponDesc
	{
		color: #fff;
		font-size: 14px;
	}
	.preBuyImageTempalet1
	{
		height: 65px;
		object-fit: contain;
		position: absolute;
		right: 28px;
		bottom: 22px;
	} */

	.preBuyCard
	{
		width: fit-content;
		margin-bottom: 20px;
	}
	.preBuyTemplate
	{
		cursor: pointer;
		min-width: 310px;
	}
	.viewRestBtn
	{
		background: #FFC60C;
	}
	.preBuyImage
	{
		object-fit:cover;
		min-width: 310px;
		/* min-height: 160px; */
		min-height: 166px;
		border-radius:8px;
		background-position: 50%;
		background-repeat: no-repeat;
		/* border-radius: 5px; */
		overflow: hidden;
		background-size: cover;
	}
	.Voucheropacity-mask
	{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
		border-radius:8px;
		background-color: rgba(0,0,0,.2);
	}
	.templateType3 .preBuyImage .Voucheropacity-mask
	{
		background-color: rgba(0,0,0,.4)!important;
	}
	.voucherDetails
	{
		padding: 20px 16px;
		height: 100%;
	}
	.preBuyCouponTitle
	{
		font-family: 'GothamMedium';
		color: #fff;
		font-size: 17px;
	}
	.PreCouponDesc
	{
		color: #fff;
		font-size: 14px;
		/* display: -webkit-box; */
	}
	.preBuyImageTempalet1
	{
		height: 65px!important;
		object-fit: contain;
		position: absolute;
		right: 20px;
		bottom: 22px;
		width: fit-content!important;
		/* float: right;
		position: relative;
		top: 65px;
		right: 0; */
	}
	.preBuyImage, .inImage
	{
		max-width: 320px!important;
		max-height: 190px!important;
	}
	.voucherStatus
	{
		position: absolute;
		right: 22px;
	}
	.viewRestBtnAnch
{
	position: absolute;
	bottom: 18px;
}
	.modalTerms
	{
		font-size: 16px;
		color: #212121;
	}
	.modalTermsDesc
	{
		font-size: 14px;
		color: #777777;
	}

		@media (max-width:991px) {
			.restBannerImg
			{
				display: none;
			}
		}

		/* restaurant Details page Style */
		.ratingcss{
	border-radius: 5px 5px 5px 0;
	font-size: 15px;
	font-size: 0.9375rem;
	padding: 8px;
	line-height: 1.1;
	}
	.reviewcl{
		border-left: 1px solid #ededed;
		padding-left: 18px;
	}
	.review_card{
		margin-bottom: 15px !important;
		padding: 15px 15px 15px 15px !important;
	}
</style>

<!-- style for all pages -->
<style>
#modal-viewDish___BV_modal_header_
{
	border-bottom:transparent;
}
#modal-cuisinePopup___BV_modal_header_
{
	border-bottom:transparent;
}
.owl-carousel
{
	z-index: 0!important;
}
.owl-item
{
	width: 310px!important;
	height: 166px;
}
.swal2-confirm
{
	width: 124px!important;
}

.vue-picker__opener{
			border:transparent!important;
		}
	.custom-checkbox .custom-control-input:checked {
		background-color: #F3766A;
		border-color: #F3766A;
	}
	.custom-checkbox .custom-control-input{
		margin-right: 7.6px;
	}
	.vsa-item__trigger__content{
		font-size:14px;
		color:#777;
	}
	.modal-body{
		padding: 30px 25px 10px 25px;
	}
	.mainCart h4
	{
		/* padding: 20px 0px; */
		color: #777!important;
	}
	ul#coupons {
		margin-bottom: 0px;
		padding-left: 7px;
	}
	ul#coupons li{
		display: inline-block;
		margin-right: 10px;
		border-radius: 5px;
		background: #fff;
		border: 1px solid #E6E6E6;
		padding: 6px 10px;
	}
	ul#coupons li p{
		margin-bottom: 0px;
		color: #000;
		font-size: 14px;
	}
	ul#coupons li span{
		color: #777;
		font-size: 12px;
	}
	#modal-viewDish___BV_modal_body_::-webkit-scrollbar
		{
			width: 3px!important;
			background-color: #F5F5F5!important;
		}

		#modal-viewDish___BV_modal_body_::-webkit-scrollbar-thumb
		{
			border-radius: 10px!important;
			background-color: #e0e0e0!important;
			border: 2px solid #e0e0e0!important;
		}
		.swal2-backdrop-show
		{
			background-color: rgba(0,0,0,.3);
		}
	/* ul#coupons li a{
		background: #fff;
		padding: 0px 12px;
		height: 40px;
		line-height: 40px;
		font-size: 14px;
	} */
	/* ul#coupons li a.active{
		background: #F3766A;
		color: #fff!important;
		border:none!important;
	} */
	.modal-title {
	font-style: normal;
	font-weight: 300;
	font-size: 24px;
}
#modal-allReviewsmodal___BV_modal_header_ .close {
	font-size: 40px !important;
	font-weight: 400 !important;
}
.feedBackSection {
	padding-top: 15px;
}
.strip {
	position: relative;
	margin-bottom: 15px;
}
.menu_item {
	background: #FFFFFF;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	border-radius: 5px;
	display: block;
	position: relative;
	padding: 10px;
	margin-bottom: 15px;
}
.modal-title {
	color: #663333;
}
#modal-allReviewsmodal___BV_modal_header_ {
	padding-bottom: 10px !important;
}
#modal-allReviewsmodal___BV_modal_body_ {
	padding: 10px 25px 10px 25px !important;
}
.userName {
	color: #663333;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 8px;
}
.fdbDate {
	padding-right: 0;
	color: #979797;
	font-size: 13px;
	font-weight: 300;
	margin-bottom: 8px;
}
.starRating {
	font-size: 9px;
	color: #663333;
	margin-bottom: 8px;
}
.userReviewText {
	color: #663333;
	font-size: 14px;
	margin-bottom: 0px;
	word-break: break-word;
}
.adminReply {
	color: rgb(102, 51, 51);
	font-size: 14px;
	font-weight: 600;
}
.replyBox {
	border-left: 5px solid #F3766A;
}
</style>